import { Box, Flex, Icon, Text } from "@engaging-tech/components"
import { Link, usePaths } from "@engaging-tech/routing"
import React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { FeaturedBooks } from "../../gardners"
import { libraryPostTypes } from "../../userGeneratedContent/lib/postTypes"
import { yourProfileSelectors } from "../../yourProfile/index"
import DiscoverLoader from "../containers/DiscoverLoader"
import Featured from "../containers/Featured"

const Articles = Featured(libraryPostTypes.articles)
const Podcasts = Featured(libraryPostTypes.podcasts)
const Lectures = Featured(libraryPostTypes.lectures)
const Research = Featured(libraryPostTypes.research)
const Books = FeaturedBooks()

const RoundedFlex = styled(Flex)`
  border-radius: 6px;
`

const Discover = () => {
  const paths = usePaths()
  const isPremium = useSelector(yourProfileSelectors.getIsPremium)

  return (
    <DiscoverLoader>
      <Box width={1 / 1} maxWidth={1000}>
        <Box mb={4}>
          <Text fontSize={5} fontWeight={700} as="h2" mb={1}>
            Discover educational content to grow and develop your work life
          </Text>
          <Text fontSize={3} color="primary.0">
            Publish your own{" "}
            <Text fontSize={3} color="primary.0" fontWeight={700}>
              <Link to="/business-library/browse/podcasts">podcasts</Link>
            </Text>
            ,{" "}
            <Text fontSize={3} color="primary.0" fontWeight={700}>
              <Link to="/business-library/browse/research">research</Link>
            </Text>
            ,{" "}
            <Text fontSize={3} color="primary.0" fontWeight={700}>
              <Link to="/business-library/browse/articles">articles</Link>
            </Text>{" "}
            and{" "}
            <Text fontSize={3} color="primary.0" fontWeight={700}>
              <Link to="/business-library/browse/lectures">lectures.</Link>
            </Text>
          </Text>
          {!isPremium && (
            <Link to={paths.getPremium.index}>
              <RoundedFlex
                bg="primary.0"
                width={320}
                height={50}
                alignItems="center"
                alignContent="center"
                justifyContent="center"
                mt={3}
              >
                <Icon name="stars" color="yellow.0" mr={2} />{" "}
                <Text color="light.0" width="auto" fontSize={14}>
                  Get{" "}
                  <Text color="yellow.0" fontSize={14} width="auto" fontWeight={700}>
                    WorkL Premium
                  </Text>{" "}
                  for a 20% discount.
                </Text>
              </RoundedFlex>
            </Link>
          )}
        </Box>
        {/* <Books /> */}
        <Articles />
        <Podcasts />
        <Lectures />
        <Research />
      </Box>
    </DiscoverLoader>
  )
}

export default Discover
