import { Box, Button, Card, Flex, Paragraph, Text, List } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import { getConfig } from "@engaging-tech/ssr-config"
import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

import { happinessSurveySelectors } from ".."
import { CountriesLoader, IndustriesLoader, SurveyJobRolesLoader } from "../../misc"
import SelectShaped from "../../ui/components/SelectShaped"
import SiteLogo from "../../ui/components/SiteLogo"
import Loader from "../containers/Loader"
import { SponsorLogos as logos } from "../lib/VoiceAwardsLibrary"
import SponsorLogo from "./SponsorLogo"
import VoiceAward from "./VoiceAward"

const happinessSurveyId = () => getConfig().surveyIds.whs

const ContentWrapper = styled(Box)`
  & > p:last-child {
    margin-bottom: 0;
  }
`

const SurveyDifference = ({ t, startSurvey }) => {
  const history = useHistory()
  const paths = usePaths()

  const isInAppView = history.location.pathname.includes("/in-app-view")
  const linkPath = isInAppView
    ? `${paths.inAppViewOrganisationSurvey.index.replace(":id", getConfig().surveyIds.wellbeing)}`
    : `${paths.survey.index.replace(":id", getConfig().surveyIds.wellbeing)}`

  return (
    <Box bg="light.0" py={3} px={[3, 4]}>
      <Flex flexDirection={["column", "row"]} alignItems="stretch">
        <Button
          variant="primary.0"
          width={1}
          onClick={() => {
            if (typeof window !== "undefined" && window.gtag) {
              window.gtag("event", "happy_at_work_test", {
                event_category: "button_click",
                event_label: "Happy at Work Test"
              })
            }
            startSurvey(`${getConfig().surveyIds.whs}`)
          }}
          border="4px solid lightgreen"
        >
          {t("choose.wfh_button", "")}
        </Button>
        <Flex mx={[0, 2]} mt={[4, 0]} flexDirection="column" justifyContent="space-between">
          <Button
            variant="primary.0"
            width={1}
            onClick={() => {
              if (typeof window !== "undefined" && window.gtag) {
                window.gtag("event", "well_being_test", {
                  event_category: "button_click",
                  event_label: "Wellbeing Test"
                })
              }
              window.open(linkPath, "_blank", "noopener")
            }}
            border="4px solid lightgreen"
          >
            {t("choose.whs_button", "")}
          </Button>
        </Flex>
      </Flex>
    </Box>
  )
}

const Welcome = ({ languages, onLoad }) => {
  const history = useHistory()
  const paths = usePaths()
  const router = useRouter()

  let returnTo = paths.home
  // Pass in a URL parameter to force return path. For example, ?returnTo=/develop-career
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search)
    const returnToURL = urlParams.get("returnTo")
    if (returnToURL) {
      returnTo = returnToURL
    }
  }
  const closeSurvey = () => router.navigate(returnTo)
  const startSurvey = id => {
    onLoad(id)
    if (history.location.pathname.includes("/in-app-view")) {
      router.navigate(paths.inAppViewHappinessSurvey.rankYourCompany)
    } else {
      router.navigate(paths.happinessSurvey.rankYourCompany)
    }
  }
  const { t, i18n } = useTranslation(["happiness_survey"])
  const welcomeSlide = Object.entries(t("welcome_slide.content", { returnObjects: true }, ""))

  const hasLoaded = useSelector(happinessSurveySelectors.getHasLoaded)

  return (
    <>
      <Loader surveyId={happinessSurveyId()} />
      <CountriesLoader />
      <IndustriesLoader />
      <SurveyJobRolesLoader />
      {hasLoaded && (
        <Card bg="light.0" mt={[5, 5, 6]} mb={[6, 5]} elevation={8} width={1 / 1} maxWidth={560}>
          <Flex flexDirection="column" py={3} px={[3, 4]}>
            <Flex width={1 / 1} justifyContent="center" mb={[3, 4]}>
              <SiteLogo />
            </Flex>
            <Text fontSize={5} fontWeight="600" color="dark.2" mb={3}>
              {t("welcome_slide.title", "")}
            </Text>
            <ContentWrapper>
              {welcomeSlide[0] && <Paragraph color="dark.2" fontSize={3} mb={2}>
                  {welcomeSlide[0][1]}
              </Paragraph>}
              {welcomeSlide[1] && <Paragraph color="dark.2" fontSize={3} mt={3} fontWeight="600">
                  {welcomeSlide[1][1]}
              </Paragraph>}
              {(welcomeSlide[2] && welcomeSlide[3]) && <List.Wrapper>
                <List.Item as="li" color="dark.2" fontSize={3}>{welcomeSlide[2][1]}</List.Item>
                <List.Item as="li" color="dark.2" fontSize={3}>{welcomeSlide[3][1]}</List.Item>
              </List.Wrapper>}
            </ContentWrapper>
          </Flex>
          <SurveyDifference t={t} startSurvey={startSurvey} />
          <VoiceAward />
          <Text py={3} px={[3, 4]}>
            {t("welcome_slide.select_language", "")}
          </Text>
          <Flex width="100%" justifyContent="center">
            <SelectShaped
              borderRadius={0}
              bg="light.0"
              width="90%"
              id="language"
              defaultValue=""
              value={i18n.language}
              onChange={e => i18n.changeLanguage(e.target.value)}
            >
              {languages?.map(lang => (
                <option key={lang.code} value={lang.code}>
                  {lang.icon} {lang.nativeName}
                </option>
              ))}
            </SelectShaped>
          </Flex>
          <Flex flexDirection="row" justifyContent="space-between" px={[3, 4]} py={3}>
            <Button variant="text.dark.2" elevation={0} onClick={closeSurvey}>
              {t("ui.cancel", "")}
            </Button>
          </Flex>
          <SponsorLogo arr={logos} />
        </Card>
      )}
    </>
  )
}

export default Welcome
