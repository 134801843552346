import { Card, Flex, H4, Text } from "@engaging-tech/components"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import GET_ALL_ACTION_PLANS from "../../../../graphql/queries/get-all-action-plans"
import useAppsyncQuery from "../../../../hooks/useAppSyncQuery"
import PlanIcon from "../../../ui/components/SurveyIcon/SurveyIcon"
import ActionPlansModal from "../containers/PlanModal"
import ActionPlanButtons from "./ActionPlanButtons"

const actionPlanStepsMapping = [
  {
    actionPlanStepType: "sixSteps_reward",
    section: "reward_recognition"
  },
  {
    actionPlanStepType: "sixSteps_information",
    section: "information_sharing"
  },
  {
    actionPlanStepType: "sixSteps_empowerment",
    section: "empowerment"
  },
  {
    actionPlanStepType: "sixSteps_wellBeing",
    section: "well_being"
  },
  {
    actionPlanStepType: "sixSteps_pride",
    section: "instilling_pride"
  },
  {
    actionPlanStepType: "sixSteps_jobSatisfaction",
    section: "job_satisfaction"
  }
]

const Wrapper = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.dark[4]};
  text-align: center;
`

const PlanBox = ({ stageIndex, stepResult, section, surveyTitle, surveyId, icon, furtherReading = true }) => {
  const displayLanguage = localStorage.getItem("i18nextLng")
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation(["results"])

  const { data } = useAppsyncQuery(GET_ALL_ACTION_PLANS, { variables: { surveyId } })

  const matchingStep = useMemo(() => {
    const correspondingMapping = actionPlanStepsMapping.find(mapping => mapping.section === section)
    return data?.previewActionPlan?.body?.steps?.find(
      step => step.type === correspondingMapping?.actionPlanStepType
    )
  }, [data, section])

  if (stepResult?.score === null || stepResult?.score === undefined) return null

  return (
    <Wrapper
      p={3}
      mb={5}
      elevation={0}
      alignItems="center"
      justifyContent="space-between"
      height={["auto", "320px"]}
    >
      <Flex width="auto" flexDirection="column" alignItems="center">
        <H4 fontSize={4} color="dark.1" mb={3} width={1 / 1}>
          {t(`action_plan.stage.${stageIndex}.heading`, "")}
        </H4>
        <PlanIcon name={icon} bg="dark.7" />
      </Flex>
      <Text fontSize={3} color="dark.2" my={3} width={1 / 1}>
        {t(`action_plan.stage.${stageIndex}.paragraph`, "")}
      </Text>
      <ActionPlanButtons
        surveyTitle={surveyTitle}
        displayLanguage={displayLanguage}
        t={t}
        stageIndex={stageIndex}
        setShowModal={setShowModal}
        surveyId={surveyId}
      />
      {showModal && (
        <ActionPlansModal
          actionPlanStep={matchingStep}
          furtherReading={furtherReading}
          section={section}
          setVisibility={setShowModal}
          stageIndex={stageIndex}
          setShowModal={setShowModal}
        />
      )}
    </Wrapper>
  )
}

export default PlanBox
